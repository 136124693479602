
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ImgList } from '@/types/insectMonitor'

@Component
export default class Carousel extends Vue {
  @Prop({ default: [] }) private imgList!: ImgList[]
  @Prop() private deviceStatus!: string
  @Prop() private width!: number
  @Prop() private height!: number
  @Prop() private iconSize!: string
  private left = 0
  private time = ''
  @Watch('imgList')
  imgListChange (list: ImgList[]) {
    if (this.deviceStatus === '2') {
      this.$emit('imgData', [])
      this.time = ''
    } else if (list && list.length > 0) {
      this.$emit('imgData', this.imgList[0].resultList)
      this.time = list[0].collectTime
    }
  }

  clickLeft () {
    if (this.left < 0) {
      this.left += this.width
      this.onData()
    }
  }

  clickRight () {
    const ulWidth = (this.imgList.length - (this.deviceStatus === '2' ? 0 : 1)) * this.width * -1
    if (this.left > ulWidth) {
      this.left -= this.width
      this.onData()
    }
  }

  onData () {
    const data = this.imgList[Math.abs(this.left / this.width) - (this.deviceStatus === '2' ? 1 : 0)]
    this.time = data ? data.collectTime : ''
    this.$emit('imgData', data ? data.resultList : [])
  }
}
