
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import echarts from 'echarts'
import { insectMonitorList, Device, ResultList } from '../../types/insectMonitor.d'
import { drawPolygon } from '@/utils/formatData'
import Carousel from './Carousel.vue'

@Component({
  name: 'DetailMap',
  components: {
    Carousel
  }
})
export default class DetailMap extends Vue {
  private drawer = false
  private switchStatus = false
  private map: AMap.Map | undefined = undefined
  private ploygons: Array<AMap.Polygon> = []
  private markers: Array<AMap.Marker> = []
  private btnDisable = false

  private onlineImg = require('@/assets/icon/insectMonitor/normal.svg')
  private onlineImgCursor = require('@/assets/icon/insectMonitor/normal-big.svg')
  private offImg = require('@/assets/icon/insectMonitor/offline.svg')
  private offImgCursor = require('@/assets/icon/insectMonitor/offline-big.svg')

  private deviceList: Array<Device> = []
  private drawerData: Device | null = null
  private drawOldData: Device | null = null
  private paramList: Array<{ icon: string; name: string; value: string; status: string }> = [{
    icon: 'gongzuozhuangtai',
    name: '工作状态',
    value: '',
    status: '1'
  }, {
    icon: 'dengguanzhuangtai',
    name: '灯管状态',
    value: '',
    status: '1'
  }, {
    icon: 'jiarecangwendu',
    name: '加热仓温度(°C)',
    value: '',
    status: '1'
  }, {
    icon: 'huanjingshidu',
    name: '环境湿度(%)',
    value: '',
    status: '1'
  }, {
    icon: 'guangzhaoqiangdu',
    name: '光照强度(lx)',
    value: '',
    status: '1'
  }, {
    icon: 'huanjingwendu',
    name: '环境温度(°C)',
    value: '',
    status: '1'
  }, {
    icon: 'yukongzhuangtai',
    name: '雨控状态',
    value: '',
    status: '1'
  }, {
    icon: 'kongkongzhuangtai',
    name: '光控状态',
    value: '',
    status: '1'
  }, {
    icon: 'wenkongzhuangtai',
    name: '温控状态',
    value: '',
    status: '1'
  }]

  private btnShow = '1'
  private typeList: Array<string> = []
  private imgList = []
  private imgData: ResultList[] = []
  private paramListShow = false

  get projectId () {
    return this.$route.params.projectId as string
  }

  created () {
    this.getProjectLocation()
  }

  destroyed (): void {
    if (this.markers) {
      this.markers.forEach((item: AMap.Marker) => {
        item.off('click', this.drawerOpen)
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', this.drawerClose)
      })
    }
    if (this.map) {
      this.map.off('click', this.drawerClose)
      this.map.clearMap()
    }
  }

  // 获取项目信息及项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.projectId
    }).then((res) => {
      this.$nextTick(() => {
        this.loadMap(res.projectInfo, res.projectLocation)
      })
    })
  }

  // 获取设备信息
  getDeviceList () {
    this.$axios.get<insectMonitorList>(this.$apis.insectMonitor.selectInsect, {
      projectId: this.projectId
    }).then((res) => {
      this.deviceList = res.devices || []
      const data = [{
        name: '在线',
        value: res.onlineAmount
      }, {
        name: '离线',
        value: res.offLineAmount
      }]
      this.drawEchart(data)
      this.drawMarker(this.deviceList)
    })
  }

  // 获取虫害轮播图
  getImgData (id: string) {
    this.$axios.get(this.$apis.insectMonitor.selectInsectImgResultByList, {
      deviceId: id,
      dateNum: '2'
    }).then((res) => {
      this.imgList = res
    })
  }

  imgDataChange (list: ResultList[]) {
    this.imgData = list
  }

  // 在线离线图
  drawEchart (data: Array<{ name: string; value: number }>) {
    const pieEchart: any = echarts.init(this.$refs.echarts as any)
    const option = {
      title: {
        text: '状态统计',
        textStyle: {
          align: 'center',
          color: '#000',
          fontSize: 14,
          fontWeight: 'bold'
        },
        top: 20,
        left: 24
      },
      color: ['#1AD1B0', '#D2D2D2'],
      legend: {
        icon: 'circle',
        itemHeight: 6,
        show: true,
        itemGap: 15,
        right: 20,
        top: '45%',
        orient: 'vertical',
        textStyle: {
          fontSize: 14 // 字体大小
        },
        formatter: (item: string) => {
          return item === '在线' ? `${item}  ${data[0].value}` : `${item}  ${data[1].value}`
        }
      },

      series: [{
        name: '',
        type: 'pie',
        clockWise: true,
        radius: ['38%', '65%'],
        center: ['35%', '58%'],
        itemStyle: {
          normal: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          }
        },
        hoverAnimation: false,
        data: data
      }]
    }
    pieEchart.setOption(option)
  }

  // 智库推荐
  goRecommend (insectName: string) {
    this.$router.push({
      name: 'insectMonitorRecommendMap',
      params: { insectName }
    })
  }

  // 渲染地图
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      this.map = map
      this.ploygons = drawPolygon(this.map, list || [], null, this.drawerClose)

      // 获取设备信息
      this.getDeviceList()

      // 点击地图关闭抽屉
      this.map.on('click', this.drawerClose)
    })
  }

  // 获取图片
  getIcon (deviceStatus: string, isCurrent: boolean) {
    if (!isCurrent) {
      return (deviceStatus === '1' ? this.onlineImg : this.offImg)
    } else {
      return deviceStatus === '1' ? this.onlineImgCursor : this.offImgCursor
    }
  }

  // 地图打点
  drawMarker (list: Array<Device>) {
    this.markers = []
    if (list && list.length > 0) {
      // 循坏将设备marker添加到地图
      list.forEach((item: Device) => {
        if (item.longitude && item.latitude) {
          const icon = this.getIcon(item.deviceStatus, false)
          const marker: any = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: icon
            }),
            offset: new AMap.Pixel(-18, -48),
            position: new AMap.LngLat(Number(item.longitude), Number(item.latitude))
          })
          marker.detail = item
          marker.on('click', this.drawerOpen)
          this.map && this.map.add(marker)
          this.markers.push(marker)
        }
      })
    }
  }

  // 抽屉打开
  drawerOpen (e: any) {
    if (this.drawerData === null || (this.drawerData && this.drawerData.deviceId !== e.target.detail.deviceId)) {
      if (this.drawerData && this.drawerData.deviceId !== e.target.detail.deviceId) {
        this.drawerClose()
      }
      this.paramList.forEach(item => {
        item.status = '1'
      })
      this.btnShow = '1'
      this.drawerData = e.target.detail
      this.switchStatus = !!((this.drawerData && this.drawerData.deviceStatus === '1'))
      this.paramList[0].value = this.wsTransform(e.target.detail.ws)
      this.paramList[1].value = this.lampTransform(e.target.detail.lamp)
      this.paramList[2].value = e.target.detail.hrt
      this.paramList[3].value = e.target.detail.ah
      this.paramList[4].value = e.target.detail.lux
      this.paramList[5].value = e.target.detail.at
      this.paramList[6].value = this.rpsTransform(e.target.detail.rps)
      this.paramList[7].value = this.lpsTransform(e.target.detail.lps)
      this.paramList[8].value = this.tpsTransform(e.target.detail.tps)
      this.drawerData && this.getDetail(this.drawerData.deviceId)
      // 点击切换大图标
      const iconClick = this.getIcon(e.target.detail.deviceStatus, true)
      e.target.setOffset(new AMap.Pixel(-22, -64))
      e.target.setIcon(iconClick)
      this.getImgData(e.target.detail.deviceId)
    }
    this.drawer = true
  }

  getDetail (deviceId: string) {
    this.$axios.get(this.$apis.insectMonitor.selectInsectConfigByList, {
      deviceId: deviceId
    }).then(res => {
      if (res.total > 0) {
        this.paramList.forEach(item => {
          item.status = '0'
        })
        res.list.forEach((item: any) => {
          if (item.type === '1') {
            this.paramList[Number(item.typeCode) - 1].status = '1'
          }
          if (item.type === '2') {
            this.btnShow = item.typeCode
          }
        })
      }
      this.paramListShow = !!this.paramList.find((item: any) => item.status === '1')
    })
  }

  switchChange () {
    this.btnDisable = true
    if (this.drawerData) {
      const datas = JSON.parse(JSON.stringify(this.drawerData))
      this.$axios.post(this.$apis.insectMonitor.updateInsectSwitch, {
        deviceId: datas.deviceId,
        deviceNumber: datas.deviceNumber,
        deviceStatus: datas.deviceStatus
      }).then(() => {
        const txt = this.switchStatus ? '开启' : '关闭'
        this.$message.success(txt + '成功。')
        this.markers.map((item: any) => {
          if (datas && (item.detail.deviceId === datas.deviceId)) {
            item.detail.deviceStatus = this.switchStatus ? '1' : '2'
            datas.deviceStatus = item.detail.deviceStatus
          }
        })
        // 换图标
        if (this.map && datas) {
          const deviceId = datas.deviceId || ''
          const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.detail.deviceId === deviceId)
          const icon = this.getIcon(datas.deviceStatus, !!this.drawerData)
          const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
          marker.setIcon(icon)
        }
      }).catch(() => {
        this.switchStatus = !this.switchStatus
      }).finally(() => {
        this.btnDisable = false
      })
    }
  }

  wsTransform (value: string) {
    return value === '0' ? '待机' : value === '1' ? '工作' : ''
  }

  lampTransform (value: string) {
    return value === '0' ? '灭' : value === '1' ? '亮' : ''
  }

  rpsTransform (value: string) {
    return value === '0' ? '正常' : value === '1' ? '雨控' : ''
  }

  lpsTransform (value: string) {
    return value === '0' ? '正常' : value === '1' ? '光控' : ''
  }

  tpsTransform (value: string) {
    return value === '0' ? '正常' : value === '1' ? '温控' : ''
  }

  // 抽屉关闭
  drawerClose () {
    if (this.drawer) {
      this.drawer = false
      // 将图标替换
      if (this.map && this.drawerData) {
        const deviceId = this.drawerData.deviceId || ''
        const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.detail.deviceId === deviceId)
        const icon = this.getIcon(this.drawerData.deviceStatus, false)
        const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
        marker.setOffset(new AMap.Pixel(-18, -48))
        marker.setIcon(icon)
      }

      // 清空抽屉
      this.drawerData = null
    }
  }
}
